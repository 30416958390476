import base from "@reflexjs/preset-base"

export default {
  preset: base,
  
  
  breakpoints: ["640px", "768px", "1024px", "1280px", "1700px"],

  colors: {
    text: "#364941",
    heading: "#364941",
    background: "#fff",
    primary: "#597365",
    primaryHover: "#52695C",
    secondary: "#fff",
    secondaryHover: "#F5F5F5",
    accent: "#FBBF24",
    accentHover: "#F0A618",
    link: "#597365",
    linkHover: "#52695C",
    pastel: "#E5F0EB",
    gray: "#6c6c6c",
    grayLight: "#F6F6F6",
    light: "#F8F8F8",
    dark: "#383838",
    border: "#000000",
    borderGray: "#E4E4E4",
    borderGreen: "#91A79B", 
    iconGreen: "#6D8879",
    iconGreenHover: "#789384",
    muted: "#fff7ea",
  },

  fonts: {
    body: '"Inter", sans-serif, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: '"Lora", "Georgia, serif"',
    monospace: "Menlo, monospace",
  },
  
  fontSizes: {
    xs: "0.75rem",      // 12px
    sm: "0.875rem",     // 14px
    md: "1rem",         // 16px
    lg: "1.125rem",     // 18px
    xl: "1.25rem",      // 20px 
    "2xl": "1.375rem",  // 22px
    "3xl": "1.5rem",    // 24px
    "4xl": "2rem",      // 32px
    "5xl": "2.275rem",  // 36px
    "6xl": "2.875rem",  // 46px
    "7xl": "4rem",      // 48px
  },

  fontWeights: {
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800"
  },

  letterSpacings: {
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em"
  },

  lineHeights: {
    none: "1rem",       // 16px
    tight: "1.125rem",  // 20px
    snug: "2rem",     // 24px
    normal: "2.125rem", // 32px
    bread: "2.5rem", // 32px
    spaced: "3rem",     // 48px
    relaxed: "3.2rem", // 52px
    loose: "4rem",      // 64px
    distant: "5.5rem",  // 96px
  },

  space: {
    0: "0",
    1: "0.25rem",   // 4px
    2: "0.5rem",    // 8px
    3: "0.75rem",   // 12px
    4: "1rem",      // 16px
    5: "1.25rem",   // 20px
    6: "1.5rem",    // 24px
    7: "1.75rem",   // 28px
    8: "2rem",      // 32px
    9: "2.25rem",   // 36px
    10: "2.5rem",   // 40px
    12: "3rem",     // 48px
    14: "3.5rem",   // 56px
    16: "4rem",     // 64px
    18: "4.5rem",   // 72px
    20: "5rem",     // 80px
    22: "5.5rem",   // 88px
    24: "6rem",     // 96px
    26: "6.5rem",   // 104px
    28: "7rem",     // 112px
    30: "7.5rem",   // 120px
    32: "8rem",     // 128px
    36: "9rem",     // 144px
    40: "10rem",    // 160px
    48: "12rem",    // 192px
    56: "14rem",    // 224px
    64: "16rem",    // 256px
    72: "18rem",    // 288px
    80: "20rem",    // 320px
  },

  sizes: {
    0: "0",
    1: "0.25rem",   // 4px
    2: "0.5rem",    // 8px
    3: "0.75rem",   // 12px
    4: "1rem",      // 16px
    5: "1.25rem",   // 20px
    6: "1.5rem",    // 24px
    7: "1.75rem",   // 28px
    8: "2rem",      // 32px
    9: "2.25rem",   // 36px
    10: "2.5rem",   // 40px
    12: "3rem",     // 48px
    14: "3.5rem",   // 56px
    16: "4rem",     // 64px
    18: "4.5rem",   // 72px
    20: "5rem",     // 80px
    22: "5.5rem",   // 88px
    24: "6rem",     // 96px
    26: "6.5rem",   // 104px
    28: "7rem",     // 112px
    30: "7.5rem",   // 120px
    32: "8rem",     // 128px
    36: "9rem",     // 144px
    40: "10rem",    // 160px
    48: "12rem",    // 192px
    56: "14rem",    // 224px
    64: "16rem",    // 256px
    72: "18rem",    // 288px
    80: "20rem",    // 320px
    auto: "auto",
    half: "50%",
    full: "100%",
  },

  borders: [
    0,
    "1px solid",
    "2px solid",
    "8px solid",
  ],

  radii: {
    none: "0",
    sm: "0.375rem",   // 6px
    md: "1rem",       // 12px
    full: "9999px",
  },

  shadows: {
    none: "none",
    xs: "0px 4px 15px rgba(0, 0, 0, 0.1)",
    sm: "0px 5px 14px rgba(0, 0, 0, 0.15)",
    md: "0px 5px 10px rgba(0, 0, 0, 0.2)",
    lg: "0px 10px 20px rgba(0, 0, 0, 0.2)",
    xl: "0px 10px 40px rgba(0, 0, 0, 0.2)",
    "2xl": "0px 30px 45px rgba(0, 0, 0, 0.25)",
  },

  gridTemplateColumns: {
    none: "none",
    1: "repeat(1, minmax(0, 1fr))",
    2: "repeat(2, minmax(0, 1fr))",
    3: "repeat(3, minmax(0, 1fr))",
    4: "repeat(4, minmax(0, 1fr))",
    5: "repeat(5, minmax(0, 1fr))",
    6: "repeat(6, minmax(0, 1fr))",
    7: "repeat(7, minmax(0, 1fr))",
    8: "repeat(8, minmax(0, 1fr))",
    9: "repeat(9, minmax(0, 1fr))",
    10: "repeat(10, minmax(0, 1fr))",
    11: "repeat(11, minmax(0, 1fr))",
    12: "repeat(12, minmax(0, 1fr))",
  },

  gridTemplateRows: {
    none: "none",
    1: "repeat(1, minmax(0, 1fr))",
    2: "repeat(2, minmax(0, 1fr))",
    3: "repeat(3, minmax(0, 1fr))",
    4: "repeat(4, minmax(0, 1fr))",
    5: "repeat(5, minmax(0, 1fr))",
    6: "repeat(6, minmax(0, 1fr))",
  },

  styles: {
    global: {
      ":root": {
        "--reach-accordion": 1,
      },
      "*": {
        m: 0,
        p: 0,
        boxSizing: "border-box",
      },
      
      html: {
        fontFamily: "body",
        fontSize: ["12px", "14px", "16px"],  // global root  responsive font sizes & root ratio number for theme "scale" tokens
        lineHeight: "normal",
        fontFeatureSettings: "'kern'",
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        textRendering: "optimizeLegibility",
      },

      hr: {
        borderBottomWidth: "1px",
        my: 6,
        width: "100%",
      },

      a: {
        color: "inherit",
        textDecoration: "inherit",
      },

      button: {
        bg: "transparent",
        backgroundImage: "none",
      },
      "img, video": {
        maxWidth: "100%",
        height: "auto",
      },
      "pre, code, kbd, samp": {
        fontFamily: "monospace",
        m: 0,
      },
    },

    root: {
      "*": {
        fontSize: ["12px", "14px", "16px"],
        listStyle: "none",
        border: "0 solid",
        borderColor: "border",
      },
    },
  },
    
  heading: {
    color: "heading",
    fontFamily: "heading",
    fontWeight: "semibold",
    lineHeight: "tight",
    letterSpacing: "normal",
  },

  h1: {
    variant: "heading",
    fontSize: ["5xl", "5xl", "6xl", null, null, "7xl"],
    lineHeight: ["relaxed", "loose", null, null, null, "distant" ],
    fontWeight: "normal",
    m: 0,
  },

  h2: {
    variant: "heading",
    fontSize: ["4xl", null, null, null, null, "6xl"],
    lineHeight: ["spaced", "spaced", null, null, null, "loose" ],
    fontWeight: "normal",
    m: 0,
  },

  h3: {
    variant: "heading",
    fontSize: ["3xl", null, null, null, null, "4xl"],
    fontWeight: "normal",
    lineHeight: "normal",
    m: 0,
  },

  h4: {
    variant: "text",
    fontSize: ["lg", "xl", "2xl"],
    lineHeight: "normal",
    letterSpacing: "wide",
    fontWeight: "bold",
    m: 0,
  },

  h5: {
    variant: "text",
    fontSize: ["lg", "lg", "xl"],
    lineHeight: "normal",
    letterSpacing: "wide",
    fontWeight: "bold",
    m: 0,
  },

  h6: {
    variant: "text",
    fontSize: ["md", "md", "lg"],
    fontWeight: "bold",
    letterSpacing: "wide",
    lineHeight: "snug",
    m: 0,
  },

  p: {
    fontFamily: "body",
    fontSize: "lg",
    fontWeight: "light",
    lineHeight: "snug",
    letterSpacing: "normal",
    margin: 0,
  },

  small: {
    fontSize: "xs",
    lineHeight: "none",
  },
  
  a: {
    color: "link",
    fontFamily: "body",
    fontSize: "lg",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    display: "inline-flex",
    alignItems: "center",
    textDecoration: "none",
    
    "&:hover, &:focus, &:active, &[aria-current=page]": {
      color: "linkHover",
      cursor: "pointer",
    },

    navLink: {
      fontFamily: "heading",
      color: "text",
      fontSize: "2xl|xl|lg",
      fontWeight: "semibold",
      lineHeight: "normal",
      letterSpacing: "normal",
      textDecoration: "none",
      "&:hover, &:focus, &:active, &[aria-current=page]": {
        color: "white",
      }
    },
    footerLink: {
      color: "light",
      fontSize: "lg",
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textDecoration: "none",
      "&:hover, &:focus, &:active, &[aria-current=page]": {
        color: "white",
      }
    },
  },
  
  hr: {
    borderTopColor: "border",
    width: "100%",
    my: 4,
  },

  svg: {
    size: ["20px", "22px", "24px"],
  },

  button: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "text",
    bg: "light",
    fontFamily: "body",
    fontSize: ["lg", "xl"],
    fontWeight: "semibold",
    lineHeight: "none",
    letterSpacing: "0px",
    textDecoration: "none",
    borderRadius: "none",
    px: [6, 10],
    py: [4, 5],
    cursor: "pointer",
    boxShadow: "none",
    transition: "all .15s ease-in",

    sm: {
      px: [3, 5, 5, 5],
      py: [2, 2, 2, 0],
      height: [10, null, null, 12],
    },

    gallery: {
      px: [6, 6, 6, 10],
      py: [0, 0, 4, 5],
      height: [10, null, null, 12],
    },

    full: {
      width: "100%",
      display: "flex",
    },

    round: {
      px: 5,
      py: 5,
    },

    lg: {
      px: 5,
      py: 4,
      height: 15,
    },

    muted: {
      bg: "muted",
      color: "text",

      "&:hover, &:focus": {
        bg: "mutedHover",
        color: "text",
      },
    },

    primary: {
      bg: "primary",
      color: "white",
      boxShadow: "lg",

      "&:hover, &:focus": {
        bg: "primaryHover",
        color: "white",
      },
    },

    secondary: {
      bg: "secondary",
      color: "text",
      boxShadow: "lg",

      "&:hover, &:focus": {
        bg: "secondaryHover",
        color: "text",
      },
    },

    accent: {
      bg: "accent",
      color: "white",

      "&:hover, &:focus": {
        bg: "accentHover",
        color: "white",
      },
    },

    link: {
      bg: "transparent",
      color: "text",
      borderColor: "transparent",

      "&:hover, &:focus": {
        bg: "light",
        color: "primary",
        boxShadow: "none",
      },
    },

    icon: {
      bg: "transparent",
      color: "text",
      borderColor: "transparent",
      p: 2,

      "&:hover, &:focus": {
        bg: "transparent",
        borderColor: "transparent",
        transform: "none",
        boxShadow: "none",
        color: "primary",
      },

      svg: {
        size: 6,
      },
    },
  },

  container: {
    px: [4, 6, 6, null, 0],
    maxWidth: [null, null, null, null, "1146px", "60vw"],

    sm: {
      maxWidth: 640,
    },

    md: {
      maxWidth: [null, null, null, "768px", "768px", "null"],
    },

    lg: {
      maxWidth: 1024,
    },

    xl: {
      maxWidth: 1280,
    },
  },

  grid: {
    gridAutoFlow: "dense",
  },
  
  flexbox: {
    alignItems: "center",
  },

  input: {
    bg: "white",
    color: "text",
    fontFamily: "body",
    fontSize: "md",
    lineHeight: "none",
    borderRadius: "none",
    borderWidth: 1,
    borderColor: "border",
    px: 6,
    py: 3,
    width: "100%",
    h: 12,

    sm: {
      py: 2,
      fontSize: "sm",
      h: 8,
    },

    lg: {
      py: 4,
      fontSize: "lg",
      h: 14,
    },

    "&:focus": {
      borderColor: "primary",
      outline: "none",
    },

    "&[type=checkbox], &[type=radio]": {
      display: "none",

      "+ label": {
        display: "inline-flex",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",

        "&:before": {
          content: "''",
          width: "14px",
          height: "14px",
          flex: "none",
          bg: "background",
          mr: 3,
          border: "2px solid",
          borderColor: "background",
          borderRadius: "none",
          boxShadow: ({ colors }) => `0 0 0 1px ${colors.border}`,
        },
      },
    },

    "&:checked + label:before": {
      bg: "primary",
      boxShadow: ({ colors }) => `0 0 0 2px ${colors.primary}`,
    },

    "&[type=radio]": {
      "+ label": {
        "&:before": {
          borderRadius: "full",
        },
      },
    },
  },

  select: {
    variant: "input",
    pl: 4,
    pr: 12,
    py: 3,
    appearance: "none",
    cursor: "pointer",
    width: "100%",
    backgroundImage:
      'url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjYXJldC1kb3duIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2FyZXQtZG93biBmYS13LTEwIiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMyMCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTMxLjMgMTkyaDI1Ny4zYzE3LjggMCAyNi43IDIxLjUgMTQuMSAzNC4xTDE3NC4xIDM1NC44Yy03LjggNy44LTIwLjUgNy44LTI4LjMgMEwxNy4yIDIyNi4xQzQuNiAyMTMuNSAxMy41IDE5MiAzMS4zIDE5MnoiPjwvcGF0aD48L3N2Zz4=")',
    backgroundPosition: "right 16px center",
    backgroundRepeat: "no-repeat",
    backgroundSize: 8,

    sm: {
      py: 2,
      fontSize: ["xs", "sm", "md"],
    },

    lg: {
      py: 4,
      fontSize: ["md", "lg", "xl"],
    },
  },

  textarea: {
    variant: "input",
  },

  fieldset: {
    border: "1px solid",
    borderColor: "border",
  },

  list: {
    variant: "text.paragraph",
    my: 6,
    ml: 6,

    li: {
      mb: 2,
    },

    unordered: {
      li: {
        listStyle: "disc",
      },
    },

    ordered: {
      li: {
        listStyle: "decimal",
      },
    },
  },
}